<template>
  <div>
    <form @submit.prevent="onFormSubmit">
      <base-textbox v-model="confirmation"
                    ref="confirmation"
                    placeholder="Type delete" />
      <base-button type="submit"
                   color="danger"
                   class="button-delete"
                   :disabled="deleteButtonDisabled"
                   :loading="deleting">Delete</base-button>
    </form>
    <base-alert v-if="deleteError" class="mt-4"
                type="error">We could not delete your account.</base-alert>
  </div>
</template>

<script>
import profileApi from '@/api/profileApi'
import Swal from 'sweetalert2'

export default {
  data () {
    return {
      confirmation: '',
      deleting: false,
      deleteError: false
    }
  },

  mounted () {
    this.$refs.confirmation.focus()
  },

  methods: {
    async onFormSubmit () {
      if (this.confirmation !== 'delete') {
        return false
      }

      this.deleteError = false
      this.deleting = true

      try {
        await profileApi.delete()
        this.showSuccessMessage()
      } catch (error) {
        console.error(error)
        this.deleteError = true
      }
      this.deleting = false
      this.confirmation = ''
    },

    showSuccessMessage () {
      Swal.fire({
        title: 'Success',
        text: 'An email with your delete link will be sent to ' + this.user.email,
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: true
      })
    }
  },

  computed: {
    deleteButtonDisabled () {
      return this.confirmation !== 'delete'
    },

    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>
