<template>
  <div class="col-md-6 pl-0">
    <div class="card">
      <div class="card-body">
        <div class="mb-3">
          If you delete your account we will stop checking your site and remove all associated data including your teammates accounts.
          Be careful with this action. If you still want to proceed, type "delete" in the input below.
        </div>
        <account-delete-form />
      </div>
    </div>
  </div>
</template>

<script>
import AccountDeleteForm from '@/components/Forms/AccountDeleteForm.vue'

export default {
  components: { AccountDeleteForm }
}
</script>
